import React from 'react';
import * as S from './LayoutNotFound.style';
import './LayoutNotFound.scss';
import { ThemeProvider } from 'styled-components';
import { THEME } from '../../constants';
import SEO from '../SEO/SEO';
import NavMain from '../NavMain/NavMain';
import Footer from '../Footer/Footer';
// import Footer from '../Footer/Footer';

const headerLinks = [
  { text: 'Home', to: '/' },
  { text: 'Blog', to: '/blog' },
];

function LayoutNotFound({ children }) {
  return (
    <ThemeProvider theme={THEME}>
      <SEO title="404: Not found" />
      <NavMain links={headerLinks} page="404" />
      {/* <Header siteTitle={data.site.siteMetadata.title} links={headerLinks} /> */}
      <S.Wrapper>
        <S.Main>
          <h1>404</h1>
          <h2>Page not found</h2>
        </S.Main>
        <Footer />
      </S.Wrapper>
    </ThemeProvider>
  );
}

export default LayoutNotFound;
