import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(var(--vh, 1vh) * 100);
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  h1 {
    text-align: center;
    color: var(--color-primary-accent);
  }
  h2 {
    color: var(--color-text);
    text-transform: uppercase;
    font-family: 'Trump Gothic East', sans-serif;
    letter-spacing: 1px;
  }
`;
